import * as React from "react";
import theme from "msem-lib/es/theme";

const Spinner = ({ size = 10, borderSize = 3, color = theme.icon.grey, otherColor = theme.primary.foreground }) => (
  <div
    className="spinner"
    style={{
      flex: `0 0 ${size}px`,
      width: size,
      height: size,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: borderSize,
      borderColor: `${color} ${otherColor} ${otherColor} ${color}`,
    }}
  />
);

export default Spinner;
