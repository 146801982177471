import * as React from "react";
import { useIntl } from "react-intl";
import { DiscussIcon, HalfStarIcon } from "../components-utils/Icons";
import ReviewsList from "./ReviewsList";

const ReviewsDetail = (props) => {
  const intl = useIntl();
  const { reviews, clickAllReviews, dialogueUrl } = props;

  return (
    <div className="msem-reviews-detail">
      <div className="msem-reviews-detail__title">
        {intl.messages["reviews.trusted-reviews1"]}{" "}
        <span className="msem-reviews-detail__title--last-words">{intl.messages["reviews.title"]}</span>
      </div>
      <div className="msem-reviews-detail__subtitle" />
      <ReviewsList reviews={reviews} />
      <div className="msem-reviews-detail__link">
        <a rel="noopener noreferrer" target="_blank" onClick={clickAllReviews}>
          <span className="msem-reviews-detail__link-more">
            <HalfStarIcon className="msem-btn-svg-half-star" />
            {intl.messages["reviews.more-info"]}
          </span>
        </a>
        <a href={dialogueUrl} rel="noopener noreferrer" target="_blank">
          <span className="msem-reviews-detail__link-chat-customer">
            <DiscussIcon className="msem-btn-svg-discuss-icon" />
            {intl.messages["reviews.chat-customer"]}
          </span>
        </a>
      </div>
    </div>
  );
};

export default ReviewsDetail;
