import * as React from "react";
import { useIntl } from "react-intl";
import Stars from "../components-utils/Stars";
import Circle from "../components-utils/Circle";

const ReviewsNumbers = (props) => {
  const intl = useIntl();
  const { globalInfo } = props;
  const mark = globalInfo.averageMark;
  const recommendation = globalInfo.recommendation;
  return (
    <div className="msem-reviews-numbers">
      <div className="msem-reviews-number reviews-number--1">
        <Stars className="msem-reviews-number__global-mark" mark={mark} />
        <div className="msem-reviews-number__label">{intl.messages["reviews.global-mark"]}</div>
        <div className="msem-reviews-number__value">{mark}/5</div>
      </div>
      <div className="msem-reviews-number reviews-number--2">
        <div className="msem-reviews-number__label">{intl.messages["reviews.recommendation"]}</div>
        <div className="msem-reviews-number__value">{recommendation}%</div>
        <div className="msem-reviews-number__mark-progress">
          <div className="msem-reviews-number__mark-progress-value" style={{ width: recommendation + "%" }}>
            <Circle percentage={recommendation} />
          </div>
        </div>
      </div>
      <div className="msem-reviews-number reviews-number--3">
        <div className="msem-reviews-number__an">
          <span className="msem-reviews-number__value">{globalInfo.reviewsNb} </span>
          <span className="msem-reviews-number__an-label">
            {intl.messages["reviews.trusted-reviews1"]} {intl.messages["reviews.trusted-reviews2"]}
          </span>
        </div>
        <div className="msem-reviews-number__an">
          <span className="msem-reviews-number__value">{globalInfo.clientsNb} </span>
          <span className="msem-reviews-number__an-label">{intl.messages["reviews.clients-nb-desc"]}</span>
        </div>
      </div>
    </div>
  );
};

export default ReviewsNumbers;
