const needsCSS = el => el === null;

const needsJS = el => el === null;

const inject = (tag, props) => {
  const el = document.createElement(tag);
  Object.keys(props).forEach(key => {
    el[key] = props[key];
  });
  document.head.appendChild(el);
};

const injecter = (condition, tag, options) => {
  return new Promise(resolve => {
    return condition(document.getElementById(options.id)) ? inject(tag, { ...options,
      onload: resolve
    }) : resolve();
  });
};

export const loadCSS = ({
  id,
  resourceUrl,
  url
}) => {
  const href = `${resourceUrl}${url}`;
  return injecter(needsCSS, "link", {
    rel: "stylesheet",
    id,
    href
  });
};
export const loadJS = ({
  id,
  url: src
}) => {
  return injecter(needsJS, "script", {
    id,
    src
  });
};
export const getParameterByName = (_name, _url) => {
  const url = _url || window.location.href;

  const name = _name.replace(/[[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};