import * as React from "react";
import { useIntl } from "react-intl";
import DropDown from "../../components-utils/DropDown";

const DropDowns = ({ dropDowns, onChange }) => {
  const intl = useIntl();
  return (
    <div className="msem-dropdowns">
      <DropDown
        items={[{ id: 0, value: intl.messages["all-reviews.practice-title"] }, ...dropDowns.practice]}
        onChange={onChange("practice")}
      />
      <DropDown
        items={[{ id: 0, value: intl.messages["all-reviews.age-title"] }, ...dropDowns.age]}
        onChange={onChange("age")}
      />
      <DropDown
        items={[{ id: 0, value: intl.messages["all-reviews.type-cours-title"] }, ...dropDowns.typeCours]}
        onChange={onChange("typeCours")}
      />
    </div>
  );
};

export default DropDowns;
