import * as React from "react";
import { PureComponent } from "react";

class Circle extends PureComponent {
  stat = {};

  render() {
    const radius = 175;
    const offset = Math.round(((100 - this.props.percentage) / 100) * Math.round(Math.PI * radius * 2));

    return (
      <div className="msem-progress-circle">
        <svg className="msem-progress-circle__svg" width="150" height="150" viewBox="-25 -25 400 400">
          <circle className="msem-progress-circle__empty" cx="175" cy="175" r={radius} />
          <circle
            className="msem-progress-circle__plain"
            cx="175"
            cy="175"
            r={radius}
            strokeDasharray="1100"
            strokeDashoffset="1100"
            style={{ strokeDashoffset: offset }}
          />
        </svg>
      </div>
    );
  }
}

export default Circle;
