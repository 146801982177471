import { configuration } from "./config";
import { fetcher } from "msem-lib/es/services/fetcher";

const post = (data) => {
  const { serverUrl } = configuration();
  return fetcher(serverUrl, "POST")(data);
};

export const getGlobalReviews = (payload) => {
  const path = "/api/reviews/global";
  return post({ path, payload });
};

export const getAllReviews = (payload) => {
  const path = "/api/reviews/details";
  return post({ path, payload });
};
