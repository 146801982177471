export default {
  primary: {
    background: "#c1142b",
    foreground: "#fff",
    light: "#f1f1f1",
    lighter: "#f9f9f9",
    dark: "#585858",
    grey: "#e0e0e0",
    error: "#ff5722",
    active: "#c20809"
  },
  icon: {
    light: "#ababab",
    highlight: "#bfad87",
    grey: "#747474",
    fillLight: "#fff"
  },
  text: {
    background: "transparent",
    foreground: "#4d4d4d",
    light: "#585858",
    darkGrey: "#747474",
    error: "#ff5722",
    active: "#fff"
  },
  fontSize: {
    huge: "34px",
    massive: "24px",
    bigger: "20px",
    big: "18px",
    medium: "16px",
    normal: "14px",
    small: "13px",
    smaller: "12px",
    tiny: "11px"
  },
  fontWeight: {
    thin: "400",
    normal: "400",
    bold: "700",
    bolder: "900"
  },
  booking: {
    available: "#80b322",
    unavailable: "#c20809"
  },
  status: {
    available: "#80b322",
    unavailable: "#c20809",
    fetching: "#fff"
  },
  cancellationGraph: {
    green: "#80b322",
    orange: "#f5a623",
    red: "#ea4a4a"
  }
};