import * as React from "react";
import { Component } from "react";
import classnames from "classnames";

import { LeftArrowIcon, RightArrowIcon } from "./Icons";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.paginationTop = React.createRef();
  }

  clickChangePage = (page, maxPage) => () => {
    const displayedPages = 9;
    const halfDisplayed = displayedPages / 2;

    const start = Math.ceil(
      page > halfDisplayed ? Math.max(Math.min(page - halfDisplayed, maxPage - displayedPages), 0) : 0
    );
    const end =
      Math.ceil(page > halfDisplayed ? Math.min(page + halfDisplayed, maxPage) : Math.min(displayedPages, maxPage)) - 1;
    this.props.onChange(page, start, end);
    window.scrollTo(0, this.paginationTop.current.offsetTop);
  };

  renderLeftButton() {
    const { maxPage, currentPage } = this.props;

    return (
      <div
        className="msem-pagination__item"
        onClick={() => {
          if (currentPage > 0) this.clickChangePage(currentPage - 1, maxPage)();
        }}
      >
        <LeftArrowIcon viewBox="0 0 24 24" width="35px" />
      </div>
    );
  }

  renderNumbers(maxPage) {
    const { currentPage, minPageDisplay, maxPageDisplay } = this.props;
    const pages = new Array(maxPage).fill(1);

    return pages.reduce((acc, page, index) => {
      const classes = classnames("msem-pagination__item", {
        "msem-pagination__item--active": index === currentPage,
      });

      if (index < minPageDisplay || index > maxPageDisplay) return acc;
      return [
        ...acc,
        <div key={index} onClick={this.clickChangePage(index, maxPage)} className={classes}>
          {index + 1}
        </div>,
      ];
    }, []);
  }

  renderRightButton() {
    const { maxPage, currentPage } = this.props;

    return (
      <div
        className="msem-pagination__item"
        onClick={() => {
          if (currentPage < maxPage - 1) this.clickChangePage(currentPage + 1, maxPage)();
        }}
      >
        <RightArrowIcon viewBox="0 0 24 24" width="35px" />
      </div>
    );
  }

  render() {
    const { children, maxPage } = this.props;

    return (
      <div className="msem-pagination" ref={this.paginationTop}>
        {children}
        <div className="msem-pagination__bar">
          {this.renderLeftButton()}
          {this.renderNumbers(maxPage)}
          {this.renderRightButton()}
        </div>
      </div>
    );
  }
}

export default Pagination;
