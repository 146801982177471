import * as React from "react";
import * as ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { loadCSS } from "msem-lib/es/services/loader";
import Reviews from "./components/Reviews";
import { configuration } from "./services/config.js";
import fr from "./locales/fr.json";
import en from "./locales/en.json";

window.openReviews = ({ language, school, containerId, useDefaultCSS }) => {
  const messages = language === "fr" ? fr : en;

  if (useDefaultCSS) {
    const { resourceUrl } = configuration();
    loadCSS({
      id: "msem-reviews-css",
      resourceUrl,
      url: "/default.css",
    });
  }

  ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
      <Reviews language={language} school={school} />
    </IntlProvider>,
    document.getElementById(containerId)
  );
};
