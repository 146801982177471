import * as React from "react";
import { PureComponent } from "react";
import { injectIntl } from "react-intl";

import ReviewsDetail from "./ReviewsDetail";
import ReviewsNumbers from "./ReviewsNumbers";
import AllReviews from "./allReviews/AllReviews";

import Loader from "../components-utils/Loader";
import { CardHeaderIcon } from "../components-utils/Icons";

import { getGlobalReviews } from "../services/api";

class Reviews extends PureComponent {
  state = {};

  async componentDidMount() {
    try {
      const data = await getGlobalReviews({
        school: this.props.school,
        language: this.props.language,
      });
      if (data.error !== undefined) {
        this.setState({ errorMessage: data.error });
      } else {
        const globalInfo = {
          ...data,
          reviews: data.reviews.map((review) => {
            const text = review.text.length > 255 ? review.text.substring(0, 230) + "..." : review.text;

            return { ...review, text };
          }),
        };
        this.setState({ globalInfo });
      }
    } catch (e) {
      this.setState({ errorMessage: e.error });
    }
  }

  clickAllReviews = () => {
    this.setState({ allReviews: !this.state.allReviews });
  };

  renderError() {
    const { intl } = this.props;

    return (
      <div className="msem-reviews-error">
        <div className="msem-reviews-error__title">
          <CardHeaderIcon viewBox="0 0 24 24" width="25px" className="msem-reviews-error__title-icon" />
          <div className="msem-reviews-error__title-message">{intl.messages["error.title"]}</div>
        </div>
        <div className="msem-reviews-error__content">{intl.messages["error.content"]}</div>
      </div>
    );
  }

  render() {
    const { errorMessage, globalInfo, allReviews } = this.state;

    return globalInfo !== undefined && globalInfo.reviewsNb > 0 ? (
      <div className="msem-reviews">
        <div className="msem-reviews__content">
          <ReviewsDetail
            reviews={globalInfo.reviews}
            dialogueUrl={globalInfo.dialogueUrl}
            language={this.props.language}
            clickAllReviews={this.clickAllReviews}
          />
          <ReviewsNumbers globalInfo={globalInfo} />
        </div>
        {allReviews && (
          <AllReviews school={this.props.school} language={this.props.language} number={globalInfo.reviewsNb} />
        )}
      </div>
    ) : errorMessage === undefined ? (
      <Loader />
    ) : (
      ""
    );
  }
}

export default injectIntl(Reviews);
