import * as React from "react";
import { PureComponent } from "react";

import { FullStarIcon, HalfStarIcon } from "../components-utils/Icons";

const renderEmptyStar = (number) => {
  return <FullStarIcon key={number} width="20px" className="msem-reviews-star__empty-star" />;
};

const renderHalfStar = (number) => {
  return <HalfStarIcon key={number} width="20px" />;
};

const renderFullStar = (number) => {
  return <FullStarIcon key={number} width="20px" />;
};

class Stars extends PureComponent {
  render() {
    const mark = parseFloat(this.props.mark);
    const numbers = new Array(5).fill(1).map((x, i) => i + 1);

    return (
      <div className={this.props.className}>
        {numbers.map((number) => {
          return number <= mark
            ? renderFullStar(number)
            : number - 0.5 <= mark && mark < number
            ? renderHalfStar(number)
            : renderEmptyStar(number);
        })}
      </div>
    );
  }
}

export default Stars;
