import * as React from "react";
import { PureComponent } from "react";
import classnames from "classnames";

import { LeftArrowIcon } from "./Icons";

class DropDown extends PureComponent {
  state = {};

  componentWillMount() {
    const { items } = this.props;
    const selected = items.find((item) => item.id === this.props.selected);

    document.addEventListener("mousedown", this.clickDropdown);
    this.setState({ selected: selected ? selected : items[0] });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickDropdown);
  }

  clickDropdown = (e) => {
    if (this.dropdownRef.contains(e.target)) this.setState({ opened: !this.state.opened });
    else if (!this.contentRef.contains(e.target)) this.setState({ opened: false });
  };

  clickItem = (selected) => () => {
    this.props.onChange(selected);
    this.setState({ selected, opened: false });
  };

  renderTitle() {
    const { selected, opened } = this.state;
    const titleClasses = classnames("msem-dropdown__title", {
      "msem-dropdown__title--selected": selected.id !== 0,
    });

    return (
      <div className={titleClasses} ref={(dropdownRef) => (this.dropdownRef = dropdownRef)}>
        {selected.value}
        <LeftArrowIcon
          viewBox="0 0 24 24"
          style={{
            transform: "rotate(" + (opened ? "90deg" : "270deg") + ")",
          }}
          className="msem-dropdown__title-icon"
        />
      </div>
    );
  }

  renderItem(item) {
    const { selected } = this.state;
    const classes = classnames("msem-dropdown__item", {
      "msem-dropdown__item--selected": item.id === selected.id,
    });

    return (
      <div className={classes} key={item.id} onClick={this.clickItem(item)}>
        {item.value}
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const { opened } = this.state;
    const classes = classnames("msem-dropdown__content", {
      "msem-dropdown__content--open": opened,
    });

    return (
      <div className="msem-dropdown">
        {this.renderTitle()}
        <div className={classes} ref={(contentRef) => (this.contentRef = contentRef)}>
          {items.map((item) => this.renderItem(item))}
        </div>
      </div>
    );
  }
}

export default DropDown;
