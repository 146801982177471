import * as React from "react";
import { useIntl } from "react-intl";
import Stars from "../../components-utils/Stars";

const Mark = ({ review, title, field }) => {
  const intl = useIntl();
  return (
    <div className="msem-all-review__mark">
      {intl.messages[title]}
      <Stars className="msem-all-reviews__stars" mark={review[field]} />
    </div>
  );
};

const Review = ({ review }) => {
  const intl = useIntl();
  return (
    <div className="msem-all-review">
      <div className="msem-all-review__review">
        <div className="msem-all-review__client">
          <img className="msem-all-review__client-img" src={review.image} alt="" />
          <div className="msem-all-review__client-infos">
            <div className="msem-all-review__client-name">
              {review.firstname} {review.lastname}
            </div>
            <div className="msem-all-review__stay">
              {intl.messages["all-reviews.stay"]}
              {review.experienceEndDate}
            </div>
            <div className="msem-all-review__client-lessons">
              <div className="msem-all-review__client-lesson">{review.practice}</div>
              <div className="msem-all-review__client-lesson">{review.typeCours}</div>
              <div className="msem-all-review__client-lesson">{review.age}</div>
            </div>
          </div>
        </div>
        <div className="msem-all-review__content">
          <div className="msem-all-review__title">
            {intl.messages["all-reviews.from"]} {review.firstname} {review.lastname}
          </div>
          <div className="msem-all-review__message">{review.text}</div>
          <div className="msem-all-review__date">
            {intl.messages["all-reviews.posted"]}
            {review.date}
          </div>
        </div>
        <div className="msem-all-review__marks">
          <div className="msem-all-review__mark-global">
            <div className="msem-all-review__mark">
              <div className="msem-all-review__mark-global-title">{intl.messages["reviews.global-mark"]}</div>
              <Stars className="msem-all-review__stars-global" mark={review.mark} />
            </div>
          </div>
          <div className="msem-all-review__mark-detail">
            <Mark title="all-reviews.security" field="score1" review={review} />
            <Mark title="all-reviews.progression" field="score2" review={review} />
            <Mark title="all-reviews.pedagogy" field="score3" review={review} />
            <Mark title="all-reviews.price" field="score4" review={review} />
            <Mark title="all-reviews.organisation" field="score5" review={review} />
          </div>
        </div>
      </div>
      {review.brandReply && <div className="msem-all-reviews__response">{review.brandReply}</div>}
    </div>
  );
};

export default Review;
