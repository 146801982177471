export const fetcher = (root, verb, showDebug) => ({
  path,
  payload,
  lang = "fr",
  headers = {}
}) => {
  return fetch(`${root}${path}`, {
    method: verb,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": lang,
      ...headers
    },
    body: payload !== undefined ? JSON.stringify(payload) : undefined
  }).then(response => {
    if (response.ok === false) return Promise.reject(new Error("error"));
    const respJson = response.json();

    if (showDebug !== undefined && typeof showDebug === "function") {
      showDebug(path, respJson);
    }

    return respJson;
  }).catch(err => ({
    error: err.message
  }));
};