import * as React from "react";
import { PureComponent } from "react";
import { injectIntl } from "react-intl";

import AllReviewsNumbers from "./AllReviewsNumbers";
import Review from "./Review";
import DropDowns from "./DropDowns";

import Loader from "../../components-utils/Loader";
import { CardHeaderIcon } from "../../components-utils/Icons";
import Pagination from "../../components-utils/Pagination";
import { getAllReviews } from "../../services/api";

class AllReviews extends PureComponent {
  state = { currentPage: 0, minPageDisplay: 0, maxPageDisplay: 8 };

  componentDidMount() {
    this.updateReviews();
  }

  updateReviews = () => {
    const { school, language, number } = this.props;
    const { practice, age, typeCours, currentPage } = this.state;

    getAllReviews({
      school: school,
      language: language,
      number: number,
      practice: practice,
      age: age,
      typeCours: typeCours,
      currentPage: currentPage,
    })
      .then((allReviews) => this.setState({ allReviews }))
      .catch((err) => {
        this.setState({ errorMessage: err.message });
      });
  };

  changeDropDowns = (field) => (selected) => {
    this.setState(
      {
        [field]: selected,
        currentPage: 0,
        minPageDisplay: 0,
        maxPageDisplay: 8,
      },
      () => this.updateReviews()
    );
  };

  changePagination = (currentPage, minPageDisplay, maxPageDisplay) => {
    this.setState({ currentPage, minPageDisplay, maxPageDisplay }, () => this.updateReviews());
  };

  renderError() {
    const { intl } = this.props;

    return (
      <div className="msem-reviews-error">
        <div className="msem-reviews-error__title">
          <CardHeaderIcon viewBox="0 0 24 24" width="25px" className="msem-reviews-error__title-icon" />
          <div className="msem-reviews-error__title-message">{intl.messages["error.title"]}</div>
        </div>
        <div className="msem-reviews-error__content">{intl.messages["error.content"]}</div>
      </div>
    );
  }

  renderPagination() {
    const { allReviews, currentPage, minPageDisplay, maxPageDisplay } = this.state;

    return (
      <div className="msem-all-reviews__content">
        <Pagination
          onChange={this.changePagination}
          maxPage={allReviews.globalInfo.maxPage}
          currentPage={currentPage}
          minPageDisplay={minPageDisplay}
          maxPageDisplay={maxPageDisplay}
        >
          {allReviews.reviews.map((review, index) => (
            <Review review={review} key={index} />
          ))}
        </Pagination>
      </div>
    );
  }

  render() {
    const { errorMessage, allReviews } = this.state;

    if (errorMessage) return this.renderError();
    else if (allReviews === undefined) return <Loader />;

    return (
      <div className="msem-all-reviews" ref={this.allReviewsRef}>
        <div className="msem-all-reviews__header">
          <DropDowns dropDowns={allReviews.globalInfo.dropDowns} onChange={this.changeDropDowns} />
          <AllReviewsNumbers globalInfo={allReviews.globalInfo} />
        </div>
        {this.renderPagination()}
      </div>
    );
  }
}

export default injectIntl(AllReviews);
