import * as React from "react";
import { useIntl } from "react-intl";

const ProgressBar = ({ value }) => (
  <div className="msem-all-reviews-number__progress">
    <div className="msem-all-reviews-number__progress-value" style={{ width: value + "%" }} />
  </div>
);

const Line = ({ title, field, info }) => {
  const intl = useIntl();
  return (
    <div className="msem-all-reviews-number__content-line">
      <div className="msem-all-reviews-number__evaluation">{intl.messages[title]}</div>
      <ProgressBar value={info[field] * (100 / info.reviewsNb)} />
      <div className="msem-all-reviews-number__review-nbr">
        {info[field]} {intl.messages["reviews"]}
      </div>
    </div>
  );
};

const AllReviewsNumbers = ({ globalInfo }) => {
  const intl = useIntl();
  return (
    <div className="msem-all-reviews-number">
      <div className="msem-all-reviews-number__title">{intl.messages["all-reviews.evaluation-title"]}</div>
      <div className="msem-all-reviews-number__content">
        <Line title="all-reviews.mark.excellent" field="fiveStar" info={globalInfo} />
        <Line title="all-reviews.mark.very-good" field="fourStar" info={globalInfo} />
        <Line title="all-reviews.mark.average" field="threeStar" info={globalInfo} />
        <Line title="all-reviews.mark.poor" field="twoStar" info={globalInfo} />
        <Line title="all-reviews.mark.horrible" field="oneStar" info={globalInfo} />
      </div>
    </div>
  );
};

export default AllReviewsNumbers;
