import * as React from "react";
import { PureComponent } from "react";

import Stars from "../components-utils/Stars";

const renderClientInfo = (review) => {
  return (
    <div className="msem-review__client">
      <div className="msem-review__client-info">
        <div className="msem-review__client-name">
          {review.firstname} {review.lastname}
        </div>
        <Stars className="msem-review__client-rate" mark={review.mark} />
      </div>
    </div>
  );
};

class Review extends PureComponent {
  render() {
    const { review } = this.props;

    return (
      <li className="msem-review">
        {renderClientInfo(review)}
        <div className="msem-review__desc">{review.text}</div>
      </li>
    );
  }
}

export default Review;
