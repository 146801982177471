import * as React from "react";
import { PureComponent } from "react";

import Review from "./Review";

class ReviewsList extends PureComponent {
  state = {};

  render() {
    const { reviews } = this.props;

    return (
      <div className="msem-reviews-list">
        <ul className="msem-reviews-list__container">
          {reviews.map((review, index) => (
            <Review key={index} review={review} />
          ))}
        </ul>
      </div>
    );
  }
}

export default ReviewsList;
