import * as React from "react";
import Spinner from "./Spinner";
import { useIntl } from "react-intl";

const loaderStyles = {
  display: "flex",
  flexDirection: "column",
  marginTop: 100,
  alignItems: "center",
  fontSize: "var(--msem-fontsize-big)",
  color: "#4d4d4d",
};
const loaderTitleStyles = {
  marginTop: 12,
};

const Loader = () => {
  const intl = useIntl();
  return (
    <div style={loaderStyles}>
      <Spinner size={32} borderSize={6} />
      <div style={loaderTitleStyles}>{intl.messages["loader.message"]}</div>
    </div>
  );
};

export default Loader;
