import * as React from "react";

const SvgIcon = (props) => (
  <svg viewBox="0 0 32 32" {...props}>
    {props.children}
  </svg>
);

export const FullStarIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 23.998l-9.887 7.217L9.92 19.582.002 12.408l12.24.027L16 .785l3.757 11.65 12.241-.027-9.919 7.174 3.808 11.633z" />
  </SvgIcon>
);

export const HalfStarIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M32,12.20075 L20.493703,11.1610833 L16,0 L11.505505,11.1611667 L0,12.20075 L8.72807921,20.1610833 L6.11176238,32 L16,25.7238333 L25.8875248,32 L23.272,20.1611667 L32,12.20075 Z M17.3093861,23.4405833 L16,22.6094167 L15.9998416,6.83633333 L18.1598416,12.20125 L18.7554851,13.6805 L20.2769109,13.818 L25.8092673,14.3178333 L21.611802,18.1460833 L20.4572673,19.1990833 L20.8031683,20.76475 L22.0605941,26.4563333 L17.3093861,23.4405833 Z" />
  </SvgIcon>
);

export const DiscussIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M11.29 4C5.05 4 0 7.28 0 11.3C0 14.1 2.54 16.72 6.48 17.9C6.64 17.95 6.76 18.11 6.76 18.32C6.72 19.34 5.62 20.94 3.56 22.34C7.17 22.26 9.23 20.33 10.33 19.26C10.66 18.97 10.87 18.76 11.07 18.65C11.15 18.61 11.2 18.61 11.27 18.61H11.97C12.27 18.61 12.55 18.56 12.84 18.56C17.31 18.16 20.92 16.06 22.11 13.31C22.23 13.03 22.31 12.78 22.4 12.49C22.52 12.12 22.56 11.71 22.56 11.3C22.53 7.28 17.48 4 11.29 4Z" />
    <path d="M26.18 24.88C29.46 23.86 31.59 21.72 31.59 19.38C31.59 16.18 27.78 13.6 22.98 13.31C21.75 16.51 17.68 18.93 12.72 19.34V19.38C12.72 22.75 16.95 25.5 22.16 25.5C22.24 25.5 22.32 25.5 22.36 25.54C22.53 25.66 22.73 25.83 22.98 26.07C23.88 26.93 25.56 28.45 28.44 28.62C26.79 27.47 25.89 26.15 25.89 25.29C25.89 25.13 26.02 24.92 26.18 24.89V24.88Z" />
  </SvgIcon>
);

export const CardHeaderIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z" />
  </SvgIcon>
);

export const LeftArrowIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
  </SvgIcon>
);

export const RightArrowIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
  </SvgIcon>
);
